import React from 'react'

//source of data
import data from '../data.json'

export default function ResumeSection() {

    const resume = data.resume

    return (
        <div className="container" data-aos="fade-up">
            <div className="section-title">
                <h2>Resume</h2>
                <p>{resume.detail}</p>
            </div>
            <div className="row">
                <div className="col-md-6 m-auto">
                    <h3 className="resume-title">Sumary</h3>
                    <div className="resume-item pb-0">
                        <h4>{data.name}</h4>
                        {/* <p><em>{resume.detail}</em></p> */}
                        <ul>
                            <li>{data.about.currentAddress}</li>
                            <li>{data.about.email}</li>
                            <li>{data.about.website}</li>
                        </ul>
                        <a href type="button" className="btn btn-outline-primary resume-button">
                            <i class='bx bxs-download' /> <span>Download My CV</span>
                        </a>    
                    </div>
                
                    <h3 className="resume-title">Education</h3>
                    {resume.educations.map((edu) => (
                        <div className="resume-item">
                            <h4>{edu.title}</h4>
                            <h5>{edu.duration}</h5>
                            <p><em><strong>{edu.institution}</strong> <br/> {edu.address}</em></p>
                            <p>{edu.course}</p>
                        </div>
                    ))}
                </div>

                {/* <div className="col-md-6 m-auto">
                    <h3 className="resume-title">Professional Experience</h3>

                    {resume.experiences.map((experience) => (
                        <div className="resume-item">
                            <h4>{experience.title}</h4>
                            <h5>{experience.duration}</h5>
                            <p><em><strong>{experience.organization}</strong>, {experience.address} </em></p>
                            <ul>

                                {experience.desc.map((des) => (
                                    <li>{des}</li>
                                ))}

                            </ul>
                        </div>
                    ))}

                </div> */}
            </div>
        </div>
    )
}
