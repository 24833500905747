import React from 'react'

//source of data
import data from '../data.json'

export default function AboutSection() {

    const about = data.about

    return (
        <div className="container" data-aos="fade-up">
            <div className="section-title">
                <h2>About</h2>
                <p>{about.aboutMe}</p>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <img src="assets/img/profile-img.jpg" className="img-fluid" alt />
                </div>
                <div className="col-lg-8 pt-4 pt-lg-0 content">
                    <h3>Software Developer &amp; Designer.</h3>
                    <p className="font-italic">{about.aboutCareerTop}</p>
                    <div className="row">
                        <div className="col-lg-6">
                            <ul>
                                <li><i className="icofont-rounded-right" /> <strong>Name:</strong> {about.name}</li>
                                <li><i className="icofont-rounded-right" /> <strong>Email:</strong> <a href = {`mailto: ${about.email}`} 
                                    target="_blank">{about.email}</a></li>
                                <li><i className="icofont-rounded-right" /> <strong>Lives in:</strong> {about.currentAddress}</li>
                                <li><i className="icofont-rounded-right" /> <strong>Degree:</strong> {about.degree}</li>
                                <li><i className="icofont-rounded-right" /> <strong>Nationality:</strong> {about.nationality}</li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <ul>
                                <li><i className="icofont-rounded-right" /> <strong>Birthday:</strong> {about.dob}</li>
                                <li><i className="icofont-rounded-right" /> <strong>Website:</strong> <a href = {`http://${about.website}`} 
                                    target="_blank">{about.website}</a></li>
                                <li><i className="icofont-rounded-right" /> <strong>From:</strong> {about.originalAddress}</li>
                                <li><i className="icofont-rounded-right" /> <strong>Phone:</strong> {about.phone}</li>
                                <li><i className="icofont-rounded-right" /> <strong>Freelance:</strong> {about.freelanceStatus}</li>
                            </ul>
                        </div>
                    </div>
                    <p>{about.aboutCareerButtom}</p>
                </div>
            </div>
        </div>
    )
}
