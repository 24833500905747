import React from 'react'

//source of data
import data from '../data.json'

export default function PortfolioSection() {

    const portfolio = data.portfolio

    return (
        <div className="container" data-aos="fade-up">
            <div className="section-title">
                <h2 className="testing">Portfolio</h2>
                <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
            </div>

            {portfolio.map((project, key) => (
                <div className="row portfolio-box d-flex " data-aos="fade-up" data-aos-delay="100">
                    <div className={"col-sm-7 portfolio-detail " + ( window.innerWidth >= 760 && key % 2 === 0 ? "order-1" : "")}>
                        <p><span class="badge badge-light font-italic text-uppercase">{project.category}</span></p>
                        <h5 className="portfolio-title">{project.name} </h5>
                        <p>{project.desc}</p>
                    </div>
                    <div className="col-sm-5 portfolio-img">
                        <img src="assets/img/profile-img.jpg" className={"mx-auto d-block " + ( window.innerWidth >= 760 && key % 2 === 0 ? "order-2" : "")} alt />
                    </div>
                </div>
            ))}

        </div>
    )
}
