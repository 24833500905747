import React from 'react'

//source of data
import data from '../data.json'

export default function SkillSection() {

    const skills = data.skills

    return (
        <div className="container" data-aos="fade-up">
            <div className="section-title">
                <h2>Skills</h2>
            </div>
            <div className="row skills-content">
                <div className="col-md-7">

                    {skills.skill_bar.map((skill) => (
                        <div className="progress">
                            <span className="skill">{skill.name} <i className="val">{skill.percentage}%</i></span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" aria-valuenow={skill.percentage} aria-valuemin={0} aria-valuemax={100} />
                            </div>
                        </div>
                    ))}

                </div>
                <div className="col-md-5">
                    <div id="myCanvasContainer">
                        <div className="container row">
                            <canvas className="m-auto" width="325" height="325" id="skillsCanvas">
                                <p>Anything in here will be replaced on browsers that support the canvas element</p>
                                <ul>
                                    {skills.skill_list.map((skill) => (
                                        <li><a href="#">{skill}</a></li>
                                    ))}
                                </ul>
                            </canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
