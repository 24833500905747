import React, { Component } from 'react'
import { Link } from 'react-router-dom'

//source of data
import data from './data.json'

//import components
import AboutSection from './components/AboutSection'
import ContactSection from './components/ContactSection'
import PortfolioSection from './components/PortfolioSection'
import ResumeSection from './components/ResumeSection'
import ServiceSection from './components/ServiceSection'
import SkillSection from './components/SkillSection'

export class MainPage extends Component {
    render() {

        const titles = data.titles
        console.log(titles)

        return (
            <div>
                {/* Mobile nav toggle button */}
                <button type="button" className="mobile-nav-toggle d-xl-none"><i className="icofont-navigation-menu" /></button>

                {/* Header */}
                <header id="header" className="d-flex flex-column justify-content-center">
                    <nav className="nav-menu">
                        <ul>
                            <li className="active"><a href="#hero"><i className="bx bx-home" /> <span>Home</span></a></li>
                            <li><a href="#about"><i className="bx bx-user" /> <span>About</span></a></li>
                            <li><a href="#resume"><i className="bx bx-file-blank" /> <span>Resume</span></a></li>
                            <li><a href="#skills"><i className='bx bx-coin-stack'></i> <span>Skills</span></a></li>
                            <li><a href="#portfolio"><i className="bx bx-book-content" /> <span>Portfolio</span></a></li>
                            <li><a href="#services"><i className="bx bx-server" /> <span>Services</span></a></li>
                            <li><a href="#contact"><i className="bx bx-envelope" /> <span>Contact</span></a></li>
                            {/* to toggle dark theme */}
                            {/* <li><a href="javascript:void(0)"><i className="bx bx-envelope toggle-theme" /> <span>Toggle Theme</span></a></li> */}
                        </ul>
                    </nav>
                </header>
                {/* End Header */}

                {/* Top Section */}
                <section id="hero" className="d-flex flex-column justify-content-center">
                    <div className="container" data-aos="zoom-in" data-aos-delay={100}>
                        <h1>{data.name}</h1>
                        <p>I'm <span className="typed" data-typed-items={titles} /></p>
                        <div className="social-links">
                            <Link to="#" className="instagram"><i className="bx bxl-instagram" /></Link>
                            <Link to="#" className="twitter"><i className="bx bxl-twitter" /></Link>
                            <Link to="#" className="facebook"><i className="bx bxl-facebook" /></Link>
                            <Link to="#" className="discord"><i className="bx bxl-discord" /></Link>
                            <Link to="#" className="linkedin"><i className="bx bxl-linkedin" /></Link>
                        </div>
                    </div>
                </section>
                {/* End Top Section */}

                {/* Main Section */}
                <main id="main">

                    {/* About Section */}
                    <section id="about" className="about site-section">
                        <AboutSection />
                    </section>
                    {/* End About Section */}

                    {/* Resume Section */}
                    <section id="resume" className="resume site-section">
                        <ResumeSection />
                    </section>
                    {/* End Resume Section */}

                    {/* Skills Section */}
                    <section id="skills" className="skills section-bg">
                        <SkillSection />
                    </section>
                    {/* End Skills Section */}

                    {/* Portfolio Section */}
                    <section id="portfolio" className="portfolio section-bg">
                        <PortfolioSection />
                    </section>{/* End Portfolio Section */}

                    {/* Services Section */}
                    <section id="services" className="services">
                        <ServiceSection />
                    </section>
                    {/* End Services Section */}

                    {/* Contact Section */}
                    <section id="contact" className="contact">
                        <ContactSection />
                    </section>
                    {/* End Contact Section */}

                </main>
                {/* End Main Section */}

                <div>
                    {/* ======= Footer ======= */}
                    <footer id="footer">
                        <div className="container">
                            <h3>{data.name}</h3>
                            <p>{data.about.email}</p>
                            <div className="social-links">
                                <Link to="#" className="instagram"><i className="bx bxl-instagram" /></Link>
                                <Link to="#" className="twitter"><i className="bx bxl-twitter" /></Link>
                                <Link to="#" className="facebook"><i className="bx bxl-facebook" /></Link>
                                <Link to="#" className="discord"><i className="bx bxl-discord" /></Link>
                                <Link to="#" className="linkedin"><i className="bx bxl-linkedin" /></Link>
                            </div>
                            <div className="copyright">
                                © Copyright <strong><span>MyResume</span></strong>. All Rights Reserved
                            </div>
                            <div className="credits">
                                {/* All the links in the footer should remain intact. */}
                                {/* You can delete the links only if you purchased the pro version. */}
                                {/* Licensing information: [license-url] */}
                                {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/free-html-bootstrap-template-my-resume/ */}
                                Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                            </div>
                        </div>
                    </footer>
                    {/* End Footer */}

                    <a href="#hero" className="back-to-top"><i className="bx bx-up-arrow-alt" /></a>
                    <div id="preloader" />
                </div>

            </div>
        )
    }
}

export default MainPage
